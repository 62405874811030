/* Navbar Styles */
.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 12rem;
  z-index: 1000;
  min-width: 320px;
}

.logo-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: auto;
  flex-shrink: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.navbar ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  flex-grow: 1;
}

.navbar ul li {
  padding: 0 0.6rem;
  text-align: center;
}

.navbar ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
}

.navbar ul li a.active,
.navbar ul li a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  background-color: transparent;
}

.menu-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 12rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 12rem);
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1001;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  padding-top: 0rem;
}

.overlay.fade-in {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.overlay.fade-out {
  animation: fadeOut 0.3s ease-in-out forwards;
}

.overlay ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
}

.overlay ul li {
  padding: 2vh;
  width: 100%;
  text-align: center;
}

.overlay ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 5vh;
  display: block;
  width: 100%;
  text-align: center;
}

.overlay ul li a.active,
.overlay ul li a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  background-color: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .navbar ul {
    display: none;
  }
}

@media (min-width: 1025px) {
  .overlay, .menu-btn {
    display: none;
  }
}