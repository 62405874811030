/* Faqs Styles */
.faqs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faqs-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem auto;
  padding: 2rem;
  max-width: 75vw;
  background-color: #e8eef7;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.faq-item {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.faq-question {
  font-size: 1.25rem;
  font-weight: bold;
  color: #222;
  margin-bottom: 0.5rem;
}

.faq-answer {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .faq-item {
    padding: 1.2rem;
  }

  .faq-question {
    font-size: 1.1rem;
  }

  .faq-answer {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .faq-question {
    font-size: 1rem;
  }

  .faq-answer {
    font-size: 0.9rem;
  }
}

.faq-link {
  color: #1A5fAb;
  text-decoration: none;
  font-weight: bold;
}

.faq-link:hover {
  text-decoration: underline;
}