/* Home Styles */
.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: calc(100vh - 12rem);
  overflow: hidden;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100%;
  text-align: center;
  color: white;
  z-index: 2;
  opacity: 0;
  animation: heroFadeIn 1.5s ease-out forwards;
}

.hero-overlay h1 {
  font-size: clamp(2.2rem, 4vw, 5rem);
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 5rem 1rem 1rem 1rem;
  line-height: 1.1;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.75);
}

.hero-overlay p {
  font-size: clamp(1.4rem, 2vw, 2rem);
  font-weight: 400;
  letter-spacing: 1.5px;
  margin-top: 1.2rem;
  line-height: 1.5;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.6);
  opacity: 0.95;
}

.hero-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7));
  z-index: 1;
}

@keyframes heroFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -55%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* About Section */
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  gap: 2rem;
  max-width: 1200px;
  margin: 4rem auto;
  text-align: center;
}

.about-text {
  flex: 1;
}

.about-text h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
}

.about-video {
  flex: 1;
  width: 100%;
  max-width: 600px;
}

.about-video iframe {
  width: 100%;
  height: 340px;
  border-radius: 8px;
}

.services-button {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: 2px solid black;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.services-button:hover {
  background-color: white;
  color: black;
}

@media screen and (min-width: 768px) {
  .about-section {
    flex-direction: row;
    text-align: left;
    gap: 4rem;
  }

  .about-text {
    flex: 1;
  }

  .about-video {
    flex: 1;
  }
}

/* Doctor Bio Section */
.doctor-bio-section {
  width: 100%;
  padding: 4rem 0;
  background: url('/public/images/doctor-bio-background.webp') no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doctor-bio-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

.doctor-bio-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.doctor-bio-text {
  flex: 1;
  text-align: center;
}

.doctor-bio-text h2 {
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.doctor-bio-text p {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  line-height: 1.6;
  color: #333;
  margin-bottom: 1rem;
}

.doctor-bio-image {
  flex: 1;
  text-align: center;
  margin-top: 1rem;
}

.doctor-bio-image img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid black;
}

.doctor-quote {
  font-size: clamp(1rem, 1.5vw, 1.1rem);
  font-style: italic;
  margin-top: 1rem;
  color: #555;
}

.doctor-signature {
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  margin-top: 0.5rem;
}

.doctor-badges {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  max-width: 20%;
  min-width: 120px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.badge-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

.badge-icon {
  color: #1A5fAb;
  font-size: clamp(0.8rem, 1.2vw, 1.2rem);
  display: flex;
  margin: 0 0 0.4rem 0;
  align-items: center;
  justify-content: center;
}

.badge h3 {
  font-size: clamp(0.8rem, 1.2vw, 1.2rem);
  margin: 0 0 0.4rem 0;
  display: flex;
  align-items: center;
}

.badge p {
  font-size: 0.8rem;
  color: #555;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .doctor-bio-content {
    flex-direction: row;
    text-align: left;
    gap: 3rem;
  }

  .doctor-bio-text {
    flex: 1;
  }

  .doctor-bio-image {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .doctor-bio-image img {
    margin: 0 auto;
  }

  .doctor-quote {
    text-align: center;
    margin-top: 1rem;
  }

  .doctor-signature {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .doctor-bio-section {
    background: url('/public/images/doctor-bio-background-small.webp') no-repeat center center/cover;
  }
  
  .doctor-bio-wrapper {
    width: 70%;
    padding: 2rem;
  }

  .doctor-bio-content {
    flex-direction: column;
    text-align: center;
    width: 100%;

  }

  .doctor-bio-text h2 {
    font-size: 1rem;
  }

  .doctor-bio-text p {
    font-size: 0.8rem;
  }

  .doctor-bio-image img {
    width: 150px;
    height: 150px;
  }

  .doctor-quote {
    font-size: 0.8rem;
  }

  .doctor-badges {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .badge {
    width: 90%;
    max-width: 100%;
  }
}

/* Certifications Section */
.certifications-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #FFFFFF;
}
  
.certifications-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
  
.certification-image {
  height: 80px;
  max-width: 150px;
  object-fit: contain;
}