/* Services Stles */
.services-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-category {
  text-align: center;
  width: 100%;
  max-width: 90vw;
}

.service-category h2 {
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 3px solid #1A5fAb;
  padding-bottom: 5px;
  margin-bottom: 15px;
  display: inline-block;
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
  padding: 0;
  list-style: none;
}

.service-item {
  background: #e8eef7;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  padding: 5px 10px;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  text-decoration: none;
  color: #144a85;
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.2s ease-in-out;
}

.service-item:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.18);
  background: #d7e3f2;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.service-details {
  padding: 40px 20px;
}

.service-detail-section {
  margin-bottom: 30px;
}

.service-detail-section h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.service-detail-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.back-button {
  margin-top: 0;
  padding: 10px 20px;
  background-color: #1A5fAb;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.back-button:hover {
  background-color: #144a8a;
}

@media (max-width: 1024px) {
  .service-item {
    width: 220px;
    height: 50px;
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .service-list {
    flex-direction: column;
    align-items: center;
  }

  .service-item {
    width: 90%;
    max-width: 300px;
    height: auto;
    padding: 12px;
  }

  .service-category h2 {
    font-size: 1.5rem;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
}