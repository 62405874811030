/* General App Styles */
html, body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  min-width: 320px;
  overflow-x: hidden
}

body.no-scroll {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

body.no-scroll .content {
  overflow-y: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  min-width: 320px;
}

.content {
  position: relative;
  top: 12rem;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 12rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  padding-bottom: 200px;
}

.banner {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.banner.loaded {
  opacity: 1;
}

.banner-overlay {
  max-width: 80%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 2rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .banner {
    height: 200px;
    font-size: 2rem;
  }

  .banner-overlay {
    font-size: 1.6rem;
    padding: 8px 16px;
  }
}
