/* Contact Styles */
.contact-container {
  width: 100%;
  min-height: calc(100vh - 12rem);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  background: url('/public/images/contact-us-background.webp') no-repeat center center/cover;
}



.contact-container.loaded {
  opacity: 1;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  margin: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  text-align: center;
  min-height: 80%;
  max-height: 100%;
}

.contact-title {
  font-size: 1.7rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #1A5FAB;
  padding-bottom: 0.5rem;
  max-width: 80%;
}

.contact-sections {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
}

.contact-info, .contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: 400px;
  width: 100%;
  align-items: center;
}

.contact-card {
  background: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border-left: 4px solid #1A5FAB;
  flex-grow: 1;
  gap: 0.8rem;
  min-height: 3rem;
}

.contact-info .contact-card:not(:last-child) {
  margin-bottom: 2rem;
}

.contact-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12);
}

.contact-icon {
  font-size: 1.4rem;
  color: #1A5FAB;
  flex-shrink: 0;
}

.contact-card p, .contact-card a {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin: 0;
  text-align: left;
  line-height: 1.2;
}

.contact-card a {
  color: #1A5FAB;
  text-decoration: none;
  font-weight: bold;
}

.contact-card a:hover {
  text-decoration: underline;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.form-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  resize: none;
}

.form-group input:focus, 
.form-group textarea:focus {
  border-color: #1A5FAB;
  outline: none;
  box-shadow: 0 0 5px rgba(26, 95, 171, 0.6);
}

.contact-form textarea[name="message"] {
  height: 10vh;
}

.submit-button {
  width: 100%;
  padding: 0.9rem;
  background-color: #1A5FAB;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.submit-button:hover {
  background-color: #144a8a;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

.submit-button:active {
  transform: translateY(1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.popup-content {
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-content h3 {
  margin: 0;
  color: #333;
}

.popup-content p {
  color: #666;
}

.popup-content button {
  margin-top: 10px;
  padding: 10px 20px;
  background: #1a5fab;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.popup-content button:hover {
  background: #144a8a;
}

.popup-content h3 {
  font-size: 1.5rem;
  color: #1a5fab;
}

.popup-content p {
  color: #333;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #1a5fab;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .contact-container {
    min-height: calc(100vh - 12rem);
  }
  .contact-content {
    width: 70%;
    min-height: 90%;
  }
  .contact-sections {
    flex-direction: column;
    align-items: center;
  }
  .contact-title {
    font-size: 1.2rem;
  }
  .contact-info {
    gap: 1rem;
  }
  .contact-info .contact-card:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .contact-card p, .contact-card a {
    text-align: center;
    font-size: 0.8rem;
  }
  .contact-form form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-group input, .form-group textarea, .submit-button, .contact-card {
    max-width: 100%;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }
  .contact-form textarea[name="message"] {
    height: 20vh;
  }
}

@media (max-width: 480px) {

  .contact-card a {
    text-align: center;
    font-size: 0.7rem;
  }
  .contact-form form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-group input, .form-group textarea, .submit-button, .contact-card {
      font-size: 0.8rem;
  }
}