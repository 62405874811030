/* Footer Styles */
.footer {
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  font-size: 14px;
  margin-top: auto;
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.footer-hours {
  width: 100%;
  max-width: 300px;
  text-align: left;
  margin-bottom: 15px;
}
  
.footer-hours h3,
.footer-address h3 {
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 8px;
  text-align: center;
  border-bottom: 2px solid #888;
  padding-bottom: 5px;
}

.hours-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.hour-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  background: #f5f5f5;
  border-radius: 5px;
  color: #333;
}
  
.closed {
  background: #eee;
  color: #666;
  font-weight: bold;
}